@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

$timing        : 265ms;
$iconColor     : #00B5F5;
$accent        : #002A8F;
$bluefade      : #0043E0;
$gradient      : #00B5F5;

@mixin transformScale($size: 1) {
    transform: scale($size);
    -ms-transform: scale($size);
    -webkit-transform: scale($size);
}

body {
    background-color: rgba($iconColor, 0.05);
}

.social-container {
  margin-top: 2vh;
  width: 400px;
  text-align: center;
  font-size: 0.9em;
}

.social-icons {
    padding: 0;
    list-style: none;
    margin: 1em;
  
    li {
        display: inline-block;
        margin: 0.15em;
        position: relative;
        font-size: 1.2em;

    }

    i {
        color: #fff;
        position: absolute;
        top: 21px;
        left: 21px;
        transition: all $timing ease-out;
    }

    a {
        display: inline-block;
      
        &:before {
            @include transformScale();
            content: " ";
            width: 60px;
            height: 60px;
            border-radius: 100%;
            display: block;
            background: linear-gradient(45deg, $iconColor, $accent);
            transition: all $timing ease-out;
        }
        
        &:hover:before {
            transform: scale(0);
            transition: all $timing ease-in;
        }
        
        &:hover i {
            @include transformScale(2.2);
            color: $iconColor;
            background: -webkit-linear-gradient(45deg, $iconColor, $accent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: all $timing ease-in;
        }
    }
  
}


.navbar{
    position: relative;
    flex-direction: row;
    display: -webkit-flex;
    display: flex;
    flex: 1;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    max-height: 7vh;
    min-height: 7vh;
    min-width: 100vw;
    max-width: 100vw;
    z-index: 10;
    background-color: '#000';
    margin-left: 0;
  }
  .navbar button{
    font-size: 30px;
    background-color: transparent;
    border-radius: 25;
    outline: transparent;
    border: transparent;
    cursor: pointer;
  }
  .hidden{
    opacity: 0;
    pointer-events: none;
  }
  .navbar h1{
    font-size: 2em;
  }
  .flex-hidden-item-nav{
    opacity: 0;
    min-width: 30px;
  }  
  .flex-hidden-item-side{
    opacity: 0;
    min-width: 15px;
  }
  .sidebar-container{
      top:0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: fixed;
      min-height: 100vh;
      max-height: 100vh;
      max-width: 40vw;
      min-width: 40vw;
      background-color: #17202a  ;
      color: white;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      margin: 0;
      -webkit-box-shadow: 5px 5px 15px 5px #000000; 
      box-shadow: 5px 5px 15px 5px #000000;
  }
  .sidebar-header{
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
    min-width: 100%;
  }
  .sidebar-items{
    text-justify: auto;
    text-align: center;
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2em;
    max-width: 100%;
  }
  .menu-close-button{
    margin-top: 0;
    margin-left: 0;
    font-size: 30px;
    color: white
  }
  .menu-title{
    margin-top: 0;
    font-size: 30px;
    text-decoration: underline;
  }
  @media (max-width: 800px) {
      .sidebar-container{
        max-width: 70vw;
        min-width: 70vw;
      }
      .navbar h1{
        margin-left: 0;
        margin-right: 0;
        font-size: 20px;
      }
      .navbar button{
        margin-left: 10px;
      }
      .sidebar-header{
        margin-top: 1em;
      }
      .menu-title{
        font-size: 20px;
        margin-top: 0.6em;
      }
      .sidebar-items{
        font-size: 1em;
      }
      .social-container {
        font-size: 1em;
      }
      .menu-close-button{
        font-size: 0.5em;
        color: white
      }
      .flex-hidden-item-nav{
        opacity: 0;
        min-width: 30px;
      }  
  }